.content-area {
  h1 {
    font-size: 2rem;
    font-weight: 600;
    color: rgb(38, 38, 38);
    margin-bottom: 2rem;
  }

  h2 {
    font-size: 1.6rem;
    font-weight: 600;
    color: rgb(38, 38, 38);
    margin-bottom: 1rem;
  }

  a {
    color: #4978bc;
  }

  a:hover {
    color: #76a3e2;
  }

  p {
    margin-bottom: 1.2rem;
  }

  ul {
    margin-bottom: 1.2rem;
    li {
      list-style-type: disc;
      list-style-position: outside;
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }
}
