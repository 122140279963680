$headerHeight: 64px;

.app {
  width: 100%;
  height: 100%;
  position: relative;

  .app-header {
    background-color: #4978bc;
    color: white;
    font-weight: 500;
    position: absolute;
    height: $headerHeight;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    justify-content: space-between;

    .header-left {
      align-self: flex-start;
      display: flex;
      height: $headerHeight;
    }

    .header-right {
      align-self: flex-end;
      display: flex;
      height: $headerHeight;
    }

    .ctech-logo {
      padding: 0 24px;
      display: flex;
      align-items: center;

      img {
        height: 48%;
      }
    }

    .header-button {
      height: $headerHeight;
      line-height: $headerHeight;
      padding: 0 24px;
      cursor: pointer;

      &.selected {
        border-bottom: 6px solid #243b5c;
      }

      &:hover {
        border-bottom: 6px solid #243b5c;
      }
    }

    .header-text {
      height: $headerHeight;
      line-height: $headerHeight;
      padding: 0 24px;
    }
  }
  .app-body {
    position: absolute;
    left: 0;
    right: 0;
    top: $headerHeight;
    bottom: 0;
    overflow-y: auto;
  }
}

// Additional things for REST API.
h4.sl-text-heading {
  padding-right: 24px;
}

// Additional things for RT API.
.bg-gray-200 {
  background-color: #ebeef5 !important;
}

.container\:base {
  overflow-y: auto;
  height: 100%;
  width: 100%;
}

.container\:base .hidden.sidebar {
  width: calc((100% - 1800px) / 2 + 300px);
}

.container\:base .hidden.sidebar .block.fixed {
  width: calc((100% - 1800px) / 2 + 300px);
  padding-left: max(24px, calc((100% - 1800px) / 2));
  min-width: 300px;
  background-color: #ebeef5;
  border-right-width: 1px;

  h1 {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
      Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 16px;
    font-weight: 700;
  }

  .text-xs {
    font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
      'Noto Color Emoji';
    font-size: 11px;
    font-weight: 700;
  }
}

.container\:base .panel--center {
  width: 100%;
  padding-left: 96px;
  padding-right: 96px;
  padding-top: 64px;
  padding-bottom: 64px;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1 1;
  height: fit-content;

  .panel-item--right {
    padding-left: 64px;
  }
}

.container\:base .panel-item,
.container\:base .panel-item--center {
  .flex.py-2 {
    div:nth-child(2) {
      flex: 1;
    }
  }
}

@media screen and (max-width: 1620px) {
  .container\:base .panel--center {
    padding-left: 200px;
  }
}
